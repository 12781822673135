import React, { useEffect, useReducer, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../utils";
import { Helmet } from "react-helmet-async";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import Button from "react-bootstrap/Button";
import Product from "../components/Product";
import SearchBox from "../components/SearchBox";

import {
  Accordion,
  Badge,
  Breadcrumb,
  Container,
  Pagination,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { LinkContainer } from "react-router-bootstrap";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        countProducts: action.payload.countProducts,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default function SearchScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search); // /search?category=Shirts
  const category = sp.get("category") || "all";
  const subCategory = sp.get("subCategory") || "all";
  const query = sp.get("query") || "all";
  const price = sp.get("price") || "all";
  const order = sp.get("order") || "newest";
  const page = sp.get("page") || 1;

  const [{ loading, error, products, pages, countProducts }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: "",
    });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `/api/products/boutique/search?page=${page}&query=${query}&category=${category}&subCategory=${subCategory}&price=${price}&order=${order}`
        );
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(error),
        });
      }
    };
    fetchData();
  }, [category, subCategory, error, order, price, query, page]);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(`/api/products/categories`);
        setCategories(data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchCategories();
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".sticky-searchbar");
    const scrollTop = window.scrollY;
    if (window.innerWidth < 576) {
      scrollTop >= 105
        ? header.classList.add("is-sticky-2")
        : header.classList.remove("is-sticky-2");
      return;
    }

    scrollTop >= 170
      ? header.classList.add("is-sticky-2")
      : header.classList.remove("is-sticky-2");
  };

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || page;
    const filterCategory = filter.category || category;
    const filterSubCategory = filter.subCategory || subCategory;
    const filterQuery = filter.query || query;
    const filterPrice = filter.price || price;
    const sortOrder = filter.order || order;
    return `/boutique/search?category=${filterCategory}&subCategory=${filterSubCategory}&query=${filterQuery}&price=${filterPrice}&order=${sortOrder}&page=${filterPage}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderedCategories = [];
  Object.keys(categories).map((category) => {
    return renderedCategories.push(
      <Accordion.Item key={category} eventKey={category}>
        <Accordion.Header
          onClick={() => {
            navigate(`/boutique/search?category=${category}`);
          }}
        >
          {category}
        </Accordion.Header>
        <Accordion.Body>
          {categories[category].map((subCategory) => {
            return (
              <Link
                key={subCategory}
                to={`/boutique/search?category=${category}&subCategory=${subCategory}`}
                className='nav-link my-2'
              >
                {subCategory}
              </Link>
            );
          })}
        </Accordion.Body>
      </Accordion.Item>
    );
  });

  let selectedCategory = category;
  let selectedSubCategory = subCategory;

  if (subCategory && categories) {
    Object.keys(
      categories[category] && typeof categories[category].map === "function"
        ? categories[category]
        : []
    ).map((currentCat) => {
      if (category === "all" || category === currentCat) {
        (categories[currentCat] &&
        typeof categories[currentCat].map === "function"
          ? categories[currentCat]
          : []
        ).map((currentSubCat) => {
          if (subCategory === currentSubCat) {
            selectedCategory = currentCat;
            selectedSubCategory = currentSubCat;
          }
        });
      }
    });
  }

  return (
    <Container className='my-5'>
      <Breadcrumb className='d-none d-md-flex'>
        <LinkContainer to={"/"} exact>
          <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={"/boutique/search"}>
          <Breadcrumb.Item>Boutique</Breadcrumb.Item>
        </LinkContainer>
        {selectedCategory !== "all" && (
          <>
            <LinkContainer to={`/boutique/search?category=${selectedCategory}`}>
              <Breadcrumb.Item active={selectedSubCategory === "all"}>
                {selectedCategory}
              </Breadcrumb.Item>
            </LinkContainer>
            {selectedSubCategory != "all" && (
              <LinkContainer
                to={`/boutique/search?category=${selectedCategory}&subCategory=${selectedSubCategory}`}
              >
                <Breadcrumb.Item active={true}>
                  {selectedSubCategory}
                </Breadcrumb.Item>
              </LinkContainer>
            )}
          </>
        )}
      </Breadcrumb>

      <Helmet>
        <title>Boutique</title>
      </Helmet>
      <h1 className='text-center'>Boutique</h1>
      <div
        className='my-4 sticky-searchbar-container'
        style={{ left: "unset !important" }}
      >
        <div className={"container sticky-searchbar px-md-0 py-2"}>
          <Row className='align-items-center d-flex align-items-center'>
            <Col md={8} lg={9} className='mt-2'>
              <SearchBox />
            </Col>
            <Col md={4} lg={3} className='mt-2'>
              <div className='text-end text-nowrap'>
                Filtrer{" "}
                <select
                  value={order}
                  onChange={(e) => {
                    navigate(getFilterUrl({ order: e.target.value }));
                  }}
                >
                  <option value='newest'>Les nouveaux produits</option>
                  <option value='lowest'>Prix : du - au +</option>
                  <option value='highest'>Prix : du + au -</option>
                </select>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col md={3}>
          <div className='text-center mb-2 d-none d-lg-block d-md-block'>
            <h4>Catégories</h4>
          </div>

          <div className='boutique-categories-menu'>
            {loading ? (
              <LoadingBox
                size='lg'
                animation='border'
                className='m-3 d-flex justify-content-center'
              />
            ) : error ? (
              <MessageBox
                variant='danger'
                className='d-none d-sm-block d-lg-block'
              >
                {error}
              </MessageBox>
            ) : (
              <Accordion className='d-none d-lg-block d-md-block'>
                {renderedCategories}
              </Accordion>
            )}
          </div>
        </Col>
        <Col md={9}>
          {loading ? (
            <LoadingBox
              size='lg'
              animation='border'
              className='m-3 d-flex justify-content-center'
            />
          ) : error ? (
            <MessageBox variant='danger'>{error}</MessageBox>
          ) : (
            <>
              <Row className='justify-content-between mb-3'>
                <Col md={6}>
                  <div>
                    {countProducts === 0 ? "Pas de " : countProducts} résultat
                    {countProducts <= 1 ? "" : "s"}
                    {price !== "all" && " : Prix " + price}
                    {query !== "all" ||
                    category !== "all" ||
                    subCategory !== "all" ||
                    price !== "all" ? (
                      <Button
                        className='btn btn-sm bg-secondary ms-2'
                        onClick={() => navigate("/boutique/search")}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} /> Effacer
                        <Badge bg='secondary'>
                          {query !== "all" && " " + "" + query + ""}
                        </Badge>
                      </Button>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {(products ?? []).length === 0 && (
                <MessageBox>Pas de résultat</MessageBox>
              )}

              <Row>
                {products.map(
                  (product) =>
                    product.isVisible === true && (
                      <Col
                        key={product._id}
                        xs={6}
                        sm={6}
                        lg={4}
                        className='mb-3 d-flex flex-column'
                      >
                        <Product product={product}></Product>
                      </Col>
                    )
                )}
              </Row>

              <div className='d-flex justify-content-center mt-3'>
                <Pagination>
                  {Number(page) != 1 && (
                    <LinkContainer to={getFilterUrl({ page: 1 })}>
                      <Pagination.First />
                    </LinkContainer>
                  )}

                  {Number(page) != 1 && (
                    <LinkContainer
                      to={getFilterUrl({ page: Number(page) - 1 })}
                    >
                      <Pagination.Prev />
                    </LinkContainer>
                  )}

                  {Number(page) >= 3 && (
                    <LinkContainer
                      to={getFilterUrl({ page: 1 })}
                      className='d-none d-md-inline'
                    >
                      <Pagination.Item>{1}</Pagination.Item>
                    </LinkContainer>
                  )}
                  {Number(page) >= 4 && (
                    <Pagination.Ellipsis
                      className='d-none d-md-inline'
                      disabled
                    />
                  )}

                  {Number(page) >= 2 && (
                    <LinkContainer
                      to={getFilterUrl({ page: Number(page) - 1 })}
                    >
                      <Pagination.Item>{Number(page) - 1}</Pagination.Item>
                    </LinkContainer>
                  )}

                  <Pagination.Item active>{Number(page)}</Pagination.Item>

                  {Number(page) < pages && (
                    <LinkContainer
                      to={getFilterUrl({ page: Number(page) + 1 })}
                    >
                      <Pagination.Item>{Number(page) + 1}</Pagination.Item>
                    </LinkContainer>
                  )}

                  {Number(page) <= pages - 3 && (
                    <Pagination.Ellipsis
                      className='d-none d-md-inline'
                      disabled
                    />
                  )}

                  {Number(page) <= pages - 2 && (
                    <LinkContainer
                      to={getFilterUrl({ page: pages })}
                      className='d-none d-md-inline'
                    >
                      <Pagination.Item>{pages}</Pagination.Item>
                    </LinkContainer>
                  )}

                  {Number(page) != pages && (
                    <LinkContainer
                      to={getFilterUrl({ page: Number(page) + 1 })}
                    >
                      <Pagination.Next />
                    </LinkContainer>
                  )}

                  {Number(page) != pages && (
                    <LinkContainer to={getFilterUrl({ page: pages })}>
                      <Pagination.Last />
                    </LinkContainer>
                  )}
                </Pagination>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
