import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { LinkContainer } from "react-router-bootstrap";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ModalZoomImage from "../components/ModalZoomImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        tissus: action.payload,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function TissuPage() {
  const [{ loading, error, tissus }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: "",
  });

  const [modalShow, setModalShow] = useState(false);

  const [tissuImage, setTissuImage] = useState("");

  const [searchTerm, setSearchTerm] = useState("");

  const cardsPerRow = 16;

  const [next, setNext] = useState(cardsPerRow);

  const handleMoreCards = () => {
    setNext(next + cardsPerRow);
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get("/api/tissus/");
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>TissuThèque</title>
      </Helmet>

      <Container className="my-5">
        <Breadcrumb className="d-none d-md-flex">
          <LinkContainer to={"/"} exact>
            <Breadcrumb.Item>Accueil</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Tissuthèque</Breadcrumb.Item>
        </Breadcrumb>
        {loading ? (
          <LoadingBox
            size="lg"
            animation="border"
            className="m-3 d-flex justify-content-center"
          />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <section className="mt-5">
              <div className="d-flex justify-content-between">
                <h2>TissuThèque</h2>
                <Form.Control
                  type="text"
                  placeholder="Rechercher un tissu..."
                  className="w-50"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
              </div>

              <Row className="mt-5">
                {(tissus && typeof tissus.map === "function" ? tissus : [])
                  .filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.name
                        .toLowerCase()
                        .includes(searchTerm.toLocaleLowerCase())
                    ) {
                      return val;
                    }
                    return "";
                  })
                  .slice(0, next)
                  .map((tissu, index) => {
                    return (
                      <Col
                        key={index}
                        xs={6}
                        md={4}
                        sm={6}
                        lg={3}
                        className="d-flex"
                      >
                        <Card className="mb-3 d-flex flex-fill">
                          <div className="py-1 d-flex flex-fill justify-content-center align-items-center text-center">
                            <h2 className="h5">{tissu.name}</h2>
                          </div>
                          {tissu.image ? (
                            <LazyLoadImage
                              value={tissu.name}
                              src={tissu.image}
                              role="button"
                              placeholderSrc="../Spinner.svg"
                              onClick={() => setModalShow(true)}
                              onClickCapture={(e) =>
                                setTissuImage(e.target.src)
                              }
                              className="card-img-top d-flex flex-fill"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center">
                              <div className="mb-5 mx-auto">
                                <FontAwesomeIcon
                                  icon={faImageSlash}
                                  size="3x"
                                />
                              </div>
                            </div>
                          )}
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
              {next < tissus?.length && (
                <div className="d-flex justify-content-center">
                  <Button
                    className="homepage-button text-dark fw-bold p-3"
                    variant="outline-light"
                    onClick={handleMoreCards}
                  >
                    Afficher plus
                  </Button>
                </div>
              )}
              <ModalZoomImage
                size="md"
                show={modalShow}
                image={tissuImage}
                onHide={() => setModalShow(false)}
              />
            </section>
          </>
        )}
      </Container>
    </>
  );
}

export default TissuPage;
