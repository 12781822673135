import React from "react";
import { Spinner } from "react-bootstrap";

export default function LoadingBox(props) {
  return (
    <div className={props.className}>
      <Spinner
        animation={props.animation}
        role="status"
        size={props.size}
        variant={props.variant}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
