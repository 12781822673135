import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Store } from "../Store";
import { getError } from "../utils";
import LoadingBox from "./LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true, successUpdate: false };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false, successUpdate: true };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false, successUpdate: false };

    default:
      return state;
  }
};
export default function ModalCategoryHome({ id, onEditSuccess, setting }) {
  const [categories, setCategories] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [chosenCategories, setChosenCategories] = useState(
    setting.chosenCategories
  );

  const settingId = id;
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [{ loading, loadingUpdate, successUpdate }, dispatch] = useReducer(
    reducer,
    {
      loading: true,
    }
  );

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/products/categories`);
        setCategories(data);
        dispatch({ type: "FETCH_SUCCESS" });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
      }
    };
    fetchCategories();
  }, []);

  const renderedCategories = [];
  Object.keys(categories).forEach(function (mappedCategory) {
    renderedCategories.push(
      <Form.Check
        className="ms-2"
        checked={chosenCategories.indexOf(mappedCategory) > -1}
        key={mappedCategory}
        id={mappedCategory}
        type="checkbox"
        label={mappedCategory}
        name={mappedCategory}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            setChosenCategories([...chosenCategories, mappedCategory]);
          } else {
            chosenCategories.splice(
              chosenCategories.indexOf(mappedCategory),
              1
            );
            setChosenCategories([...chosenCategories]);
          }
        }}
      />
    );
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: "UPDATE_REQUEST" });
      await axios.put(
        `/api/settings/${settingId}`,
        {
          _id: id,
          chosenCategories,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      onEditSuccess();
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      toast.success("Mise à jour effectuée");
      setModalShow(false);
      if (successUpdate) {
        dispatch({ type: "UPDATE_RESET" });
      }
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: "UPDATE_FAIL" });
    }
  };

  return (
    <>
      <div>
        <Button type="button" onClick={() => setModalShow(true)}>
          <FontAwesomeIcon icon={faPen} /> Modifier
        </Button>

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Catégorie mise en avant sur la page d'accueil
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={submitHandler}>
              {loading ? (
                <LoadingBox
                  size="lg"
                  animation="border"
                  className="m-3 d-flex justify-content-center"
                />
              ) : (
                <div className="colscroll">{renderedCategories}</div>
              )}
              {loadingUpdate ? (
                <LoadingBox
                  size="lg"
                  animation="border"
                  className="m-3 d-flex justify-content-center"
                />
              ) : (
                <Button
                  type="submit"
                  className="bg1 w-100"
                  variant="outline-light"
                >
                  Mettre à jour
                </Button>
              )}
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
