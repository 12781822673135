import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getError } from "../utils";
import Product from "./Product";
import SlickCarousel from "./SlickCarousel";

export default function ProductsByCategory({ category }) {
  const [productsByCategory, setProductsByCategory] = useState([]);

  const responsiveCarousel = [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  useEffect(() => {
    const fetchProductsByCategories = async () => {
      try {
        const { data } = await axios.get(
          `/api/products/boutique/search?category=${category}`
        );
        setProductsByCategory(data.products);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchProductsByCategories();
  }, []);

  if (productsByCategory.length > 0) {
    return (
      <div className='mt-3 mb-5 carousel-category-home'>
        <div className='d-flex justify-content-between'>
          <h2>{category}</h2>&nbsp;
        </div>
        <hr />
        {productsByCategory.length > 4 ? (
          <SlickCarousel
            {...{
              dots: true,
              slidesToShow: 4,
              slidesToScroll: 2,
              speed: 500,
              responsive: responsiveCarousel,
            }}
          >
            {productsByCategory.slice(0, 12).map((product) => (
              <div key={product.slug}>
                <Product product={product} />
              </div>
            ))}
          </SlickCarousel>
        ) : (
          <Row>
            {productsByCategory.slice(0, 4).map((product) => {
              return (
                <Col
                  key={product.slug}
                  xs={6}
                  sm={6}
                  md={4}
                  lg={3}
                  className='mb-3 d-flex flex-column'
                >
                  <Product product={product} />
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    );
  } else {
    return "";
  }
}
