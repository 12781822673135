import React, { useEffect } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const MentionsLegalesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="my-5">
      <Breadcrumb className="d-none d-md-flex">
        <LinkContainer to={"/"} exact>
          <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Mentions légales</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="my-5">Mentions légales</h1>
      <div>
        <p>
          Marie FARDEL, propriétaire de Rose Charlotte &amp; COMPAGNIE, boutique
          en ligne de vêtements et accessoires pour toute la famille. SARL dont
          le siège social se situe 20 rue principale à ECQUEDECQUES, enregistré
          à la CMA d’Arras. <br />
          <br /> TVA non applicable. <br />
          SIRET : 92101992300017 <br />
          Code APE : 7410Z Activités spécialisées de design. <br /> Hébergement
          du site : OVH | 2, rue Kellermann, 59100 Roubaix <br />
          Service client contact@rosecharlotte.fr <br /> Crédit photos : Marie
          FARDEL Rose Charlotte &amp; COMPAGNIE est une marque déposée auprès de
          l’INPI. <br />
          <br />
          L'intégralité du site est la propriété exclusive de la SARL Rose
          Charlotte &amp; COMPAGNIE. Aucune reproduction ou représentation ne
          peut avoir lieu sans le consentement écrit et préalable de Rose
          Charlotte &amp; COMPAGNIE. <br /> Malgré tous les soins d'usage
          apportés à la réalisation de ce site et à son actualisation régulière,
          des erreurs peuvent s'être glissées dans les informations et/ou
          documents présentés. Rose Charlotte &amp; Compagnie ne peut néanmoins
          garantir l'exactitude, la précision ou l'exhaustivité des informations
          mises à la disposition sur ce site et la responsabilité de l'éditeur
          ne peut être engagée en aucune circonstance en cas d'éventuelles
          erreurs. Si vous en constatez, merci de le signaler par courrier
          électronique à : contact@rosecharlotte.fr afin que nous procédions aux
          rectifications correspondantes. <br />
          <br /> La SARL Rose Charlotte &amp; COMPAGNIE se réserve le droit de
          corriger, à tout moment et sans préavis, le contenu. Ce site a fait
          l'objet d'une déclaration auprès de la Commission Nationale de
          l'Informatique et des Libertés. Les données nominatives enregistrées
          sur ce site seront stockées et utilisées conformément à la loi du 6
          janvier 1978 relative à l'informatique et aux libertés. Les
          utilisateurs de ce site disposent d'un droit d'accès, de rectification
          et de suppression des données les concernant, qu'ils peuvent exercer
          auprès de : Rose Charlotte &amp; COMPAGNIE 20 rue Principale 62190
          ECQUEDECQUES.
        </p>
      </div>
    </Container>
  );
};

export default MentionsLegalesPage;
